<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <!-- <HeaderMenuPanel
        :title="'AUTOMATION'"
        :filter="filter"
        :link="'/automation/detail/0'"
        :placeholder="'Search Name'"
        @search="handleSearch"
      /> -->
      <HeaderPanel
        title="AUTOMATION"
        :filter="filter"
        placeholder="Search Name"
        :hasDropdown="false"
        :hasFilter="false"
        @search="handleSearch"
        routerPath="/automation/detail/0"
      />
      <TableListPanel
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :filter="filter"
        :rows="rows"
        :pageOptions="pageOptions"
        @page="pagination"
        :showingTo="showingTo"
        @perpage="hanndleChangePerpage"
        @delete="deleteData"
      />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderMenuPanel from "@/components/automation/header/HeaderMenuPanel";
import TableListPanel from "@/components/automation/TableListPanel";
export default {
  components: {
    OtherLoading,
    HeaderMenuPanel,
    TableListPanel,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-100px",
        },
        {
          key: "event_type",
          label: "Event Type",
          class: "w-100px",
        },
        {
          key: "valid_from",
          label: "Valid From",
          class: "w-200",
        },
        {
          key: "valid_to",
          label: "Valid To",
          class: "w-200",
        },
        {
          key: "number_of_user",
          label: "Number Of User",
          thClass: "w-100px",
        },
        {
          key: "goal_percen",
          label: "Goal",
        },
        {
          key: "active",
          label: "Status",
        },

        {
          key: "is_draft",
          label: "Draft",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingTo: 0,
      confirmMsg: "",
      deleteId: 0,
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    async getList() {
      this.isBusy = true;
      await this.$store.dispatch("getList", this.filter);
      let data = this.$store.state.automation.list;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.count;
        this.isBusy = false;
        this.showingTo = this.items?.length >= 10 ? 10 : this.items.length;
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.errorAlert();
      }
    },
    handleSearch(val) {
      this.filter = val;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter = value;
      this.getList();
    },
    deleteData(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteItem(id);
        }
      });
      this.deleteId = id;
    },
    async deleteItem(val) {
      if (val) {
        this.isLoading = true;
        await this.$store.dispatch("deleteAutomation", this.deleteId);
        let data = this.$store.state.automation.respDelete;
        if (data.result == 1) {
          this.successAlert().then(() => {
            this.getList();
          });
        } else {
          this.isLoading = false;
          this.errorAlert();
        }
      }
    },
    hideModal() {
      if (this.deleteId == 0) {
        window.location.href = "/automation";
      }
    },
    pagination(value) {
      this.filter = value;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped></style>
